import {BoatPayload} from '../boat';
import {CrewPositionDto, CrewSearchStatus, ExpectedSalaryDto} from '../crew/crew.model';
import {CoverLetterDto, CrewCvAvailabilityDto} from '../crew-cv';
import {CountryDto} from '../directories';
import {JobOfferInvitationType, JobOfferStatusType, PositionUser} from '../job-offers';
import {SearchBoatTypes} from '../job-search';
import {GeolocationDtoI} from '../location';
import {PaginationDto} from '../response';
import {SearchMatchingDto} from '../search';

export class CrewSearchListDto {
  constructor(
    public readonly list: CrewSearchItemDto[],
    public readonly pagination: PaginationDto,
  ) {
  }
}

export class CrewSearchItemDto {
  constructor(
    public readonly user: CrewSearchUserDto,
    public readonly cv: CrewSearchCvDto,
    public readonly additional: CrewSearchItemAdditionalDto,
    public readonly matching: SearchMatchingDto | null,
  ) {
  }
}

export class CrewSearchUserDto {
  constructor(
    public readonly id: number,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly age: number,
    public readonly avatar: string,
    public readonly location: CrewSearchUserLocationDto,
    public readonly lastLogin: string,
  ) {
  }
}

export class CrewSearchUserLocationDto implements GeolocationDtoI {
  constructor(
    public readonly address: string,
    public readonly distanceToBoat: number,
    public readonly lat: number,
    public readonly lng: number,
  ) {
  }
}

export class CrewSearchCvDocumentItemDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
  ) {
  }
}

export class CrewSearchCvDocumentPassportDto extends CrewSearchCvDocumentItemDto {
  constructor(
    id: number,
    name: string,
    public readonly country: CountryDto,
  ) {
    super(id, name);
  }
}

export class CrewSearchCvDocumentVisaDto extends CrewSearchCvDocumentItemDto {
  constructor(
    id: number,
    name: string,
    public readonly type: string,
  ) {
    super(id, name);
  }
}

export class CrewSearchCvDocumentsDto {
  constructor(
    public readonly covidVaccination: CrewSearchCvDocumentItemDto | null,
    public readonly passport: CrewSearchCvDocumentPassportDto | null,
    public readonly visa: CrewSearchCvDocumentVisaDto | null,
  ) {
  }
}

export class CrewSearchCvDto {
  constructor(
    public readonly status: CrewSearchStatusDto,
    public readonly position: CrewPositionDto,
    public readonly lastUpdate: string,
    public readonly matching: number,
    public readonly greeny: boolean,
    public readonly expectedSalary: ExpectedSalaryDto,
    public readonly yachtExperience: number | null,
    public readonly video: string | null,
    public readonly verifiedReference: boolean,
    public readonly documents: CrewSearchCvDocumentsDto,
    public readonly workPreferenceContracts: CrewSearchContractItemDto[],
    public readonly couple: CoupleDto,
    public readonly coverLetter: CoverLetterDto,
    public readonly boatTypes: SearchBoatTypes[],
  ) {
  }
}

export class CoupleDto {
  constructor(
    public readonly id: number,
    public readonly username: string,
    public readonly position: string,
  ) {
  }
}

export class CrewSearchStatusDto {
  constructor(
    public readonly lookingJob: CrewSearchStatus,
    public readonly availability: CrewCvAvailabilityDto,
  ) {
  }
}

export class CrewSearchContractItemDto {
  constructor(
    public readonly contractTypeId: number,
  ) {
  }
}

export class CrewSearchItemAdditionalDto {
  constructor(
    public readonly favorite: boolean,
    public readonly note: string,
    public readonly status: JobOfferStatusType | null,
    public readonly hasHistory: boolean,
  ) {
  }
}

export class CrewSearchOffersHistoryDto {
  constructor(
    public readonly list: CrewSearchOfferHistoryItemDto[],
    public readonly pagination: PaginationDto,
  ) {
  }
}

export class CrewSearchOfferHistoryItemDto {
  constructor(
    public readonly jobOfferId: number,
    public readonly invitationType: JobOfferInvitationType,
    public readonly position: PositionUser,
    public readonly offerName: string,
    public readonly jobOfferKindContract: string[],
    public readonly estimatedStartDate: string | null,
    public readonly flexible: boolean,
    public readonly endDate: string,
    public readonly offeredSalary: number,
    public readonly offeredSalaryCurrency: string,
    public readonly salaryPeriod: string,
    public readonly dateSendOrApply: string,
  ) {
  }
}

export interface CrewSearchOfferHistoryPayload extends BoatPayload {
  userId: number;
  page: number;
  limit: number;
}

export class CrewSearchOfferPaginationParams {
  constructor(
    public readonly page: number = 1,
    public readonly limit = 4,
  ) {
  }
}

export interface ChangeUserFavoriteStatusI {
  isFavorite: boolean;
  userId: number;
}

export interface ChangeUserRelevantStatusI {
  isNotRelevant: boolean;
  userId: number;
}

export interface SendCrewJobOfferI {
  userName: string;
  userId: number;
}

export class UpdateUserStatusPayload {
  constructor(
    public readonly boatId: number,
    public readonly userId: number,
  ) {
  }
}
