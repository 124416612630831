import {Params} from '@angular/router';

import {AvailabilityFormI} from '@controls/availability-form';
import {ExpectedSalaryFormI} from '@controls/expected-salary-form';
import {LanguageLevelFormI} from '@controls/language-level-form';
import {SeazoneInputAutocompleteItemDto} from '@controls/seazone-autocomplete-input';
import {CheckboxGroupedValue, CheckboxGroupItem} from '@controls/seazone-checkbox-group-deprecated';
import {RangeDto} from '@controls/seazone-range-slider';
import {parseParamsToArray} from '@helpers/parse-params-to-array';

import {CrewSearchStatusItem} from '../crew';
import {
  BoatTypeDto,
  BoatTypeSectionDto,
  CountryDto,
  CvVisaGroupDto,
  LanguageDto,
  LanguageLevelDto,
  RolePositionDto,
  RolePositionTypeDto,
} from '../directories';
import {booleanToConditionalStatus, ConditionalStatus} from '../general';
import {JobOfferCoupleDto, JobOfferSearchFormI, SmokingStatus, TattooStatus} from '../job-offers';
import {SearchChipsItemDto} from '../search';

export enum CrewSearchSortType {
  LastUpdate = 1,
  SalaryMinMax,
  SalaryMaxMin,
  ExperienceMinMax,
  ExperienceMaxMin,
  LastLogin,
  RatingMinMax,
  RatingMaxMin,
  LastAction,
}

export type CrewSearchType = 'filter' | 'favorite' | 'notRelevant';

export class CrewSearchCommonInfoDto {
  constructor(
    public readonly expectedSalary: CrewExpectedSalaryCurrencyDto[],
    public readonly offers: CrewSearchJobOfferListDto,
  ) {
  }
}

export class CrewExpectedSalaryCurrencyDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly badge: string,
    public readonly min: number,
    public readonly max: number,
  ) {
  }
}

export class CrewSearchJobOfferListDto {
  constructor(
    public readonly publisched: CrewSearchCommonJobOfferItemDto[],
    public readonly unpublisched: CrewSearchCommonJobOfferItemDto[],
  ) {
  }
}

export class CrewSearchCommonJobOfferItemDto
implements Omit<JobOfferSearchFormI, 'covidVaccination' | 'coupleConnect'> {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly experienceFrom: number,
    public readonly experienceTo: number,
    public readonly jobOfferKindContract: number[],
    public readonly languageOneValue: number | null,
    public readonly languageOneLevel: number | null,
    public readonly languageTwoValue: number | null,
    public readonly languageTwoLevel: number | null,
    public readonly languageThreeValue: number | null,
    public readonly languageThreeLevel: number | null,
    public readonly jobOfferSkill: number[],
    public readonly offeredSalary: number,
    public readonly offeredSalaryMax: number,
    public readonly offeredSalaryCurrencyId: number,
    public readonly positionGroupId: number,
    public readonly positionId: number,
    public readonly smokingStatusId: SmokingStatus,
    public readonly tattooStatusId: TattooStatus,
    public readonly covidVaccination: ConditionalStatus,
    public readonly coupleConnect: JobOfferCoupleDto | number | null,
  ) {
  }
}

export class CrewSearchFilterForm {
  constructor(
    public readonly jobOfferId: number | null = null,
    public readonly positionGroup: number[] | null = null,
    public readonly position: number[] | null = null,
    public readonly lookingJobStatus: number | null = null,
    public readonly availability: AvailabilityFormI | null = null,
    public readonly contractType: number[] | null = null,
    public readonly expectedSalary: ExpectedSalaryFormI | null = null,
    public readonly professionalSkills: CheckboxGroupedValue | null = null,
    public readonly languages: LanguageLevelFormI | null = null,
    public readonly experience: RangeDto | null = null,
    public readonly notSmocking: boolean | null = null,
    public readonly notVisibleTattoos: boolean | null = null,
    public readonly greeny: boolean | null = null,
    public readonly passportCountry: number | null = null,
    public readonly visa: number[] | null = null,
    public readonly covidVaccination: boolean | null = null,
    public readonly couple: boolean | null = null,
    public readonly boatType: number[] | null = null,
    public readonly boatTypeSection: number[] | null = null,
  ) {
  }
}

export class CrewSearchFilterParams {
  readonly jobOfferId: number | null = null;
  readonly positionGroup: number[] | null = null;
  readonly position: number[] | null = null;
  readonly lookingJobStatus: number | null = null;
  readonly availabilityStatus: number[] | null = null;
  readonly availabilityDate: string | null = null;
  readonly contractType: number[] | null = null;
  readonly expectedSalaryCurrency: number | null = null;
  readonly expectedSalaryMin: number | null = null;
  readonly expectedSalaryMax: number | null = null;
  readonly professionalSkills: number[] | null;
  readonly languageOneValue: number | null = null;
  readonly languageOneLevel: number | null = null;
  readonly languageTwoValue: number | null = null;
  readonly languageTwoLevel: number | null = null;
  readonly languageThreeValue: number | null = null;
  readonly languageThreeLevel: number | null = null;
  readonly yachtExperienceMin: number | null = null;
  readonly yachtExperienceMax: number | null = null;
  readonly notSmocking: ConditionalStatus | null = null;
  readonly notVisibleTattoos: ConditionalStatus | null = null;
  readonly greeny: ConditionalStatus | null = null;
  readonly covidVaccination: ConditionalStatus | null = null;
  readonly passportCountry: number | null = null;
  readonly visa: number[] | null = null;
  readonly couple: ConditionalStatus | null = null;
  readonly boatType: number[] | null = null;

  constructor(form: CrewSearchFilterForm) {
    const {
      positionGroup,
      position,
      contractType,
      professionalSkills,
      languages,
      notSmocking,
      notVisibleTattoos,
      greeny,
      jobOfferId,
      expectedSalary,
      experience,
      availability,
      lookingJobStatus,
      covidVaccination,
      passportCountry,
      visa,
      couple,
      boatType,
    } = form;
    this.jobOfferId = jobOfferId;
    this.positionGroup = this.getArrayParam(positionGroup);
    this.position = this.getArrayParam(position);
    this.lookingJobStatus = lookingJobStatus;

    if (availability) {
      this.availabilityStatus = availability.availabilityStatus;
      const statusWithDate = this.availabilityStatus && this.availabilityStatus.some(st => st === 4);
      this.availabilityDate = statusWithDate && availability.availabilityDate || null;
    }

    this.contractType = this.getArrayParam(contractType);

    if (expectedSalary) {
      const {currency, salary} = expectedSalary;
      this.expectedSalaryCurrency = currency;
      this.expectedSalaryMin = salary && salary.min;
      this.expectedSalaryMax = salary && salary.max;
    }

    this.professionalSkills = this.getArrayParam(professionalSkills && professionalSkills.subItems);
    if (languages) {
      this.languageOneValue = languages.languageOneValue;
      this.languageOneLevel = languages.languageOneLevel;
      this.languageTwoValue = languages.languageTwoValue;
      this.languageTwoLevel = languages.languageTwoLevel;
      this.languageThreeValue = languages.languageThreeValue;
      this.languageThreeLevel = languages.languageThreeLevel;
    }

    this.yachtExperienceMin = experience && experience.min;
    this.yachtExperienceMax = experience && experience.max;
    this.notSmocking = notSmocking ? booleanToConditionalStatus(notSmocking) : null;
    this.notVisibleTattoos = notVisibleTattoos ? booleanToConditionalStatus(notVisibleTattoos) : null;
    this.greeny = greeny ? booleanToConditionalStatus(greeny) : null;
    this.covidVaccination = covidVaccination ? booleanToConditionalStatus(covidVaccination) : null;
    this.passportCountry = passportCountry;
    this.visa = this.getArrayParam(visa);
    this.couple = couple ? booleanToConditionalStatus(couple) : null;
    this.boatType = boatType;
  }

  getArrayParam(val: any[] | null): any[] | null {
    if (!val || !val.length) {
      return null;
    }
    return val;
  }
}

export class CrewSearchFilterDirectories {
  constructor(
    public readonly positionTypes: RolePositionTypeDto[],
    public readonly cvAvailableStatuses: CheckboxGroupItem[],
    public readonly skills: CheckboxGroupItem[],
    public readonly contractTypes: CheckboxGroupItem[],
    public readonly salary: CrewExpectedSalaryCurrencyDto[],
    public readonly languages: LanguageDto[],
    public readonly levels: LanguageLevelDto[],
    public readonly crewSearchStatuses: CrewSearchStatusItem[],
    public readonly allPositions: RolePositionDto[],
    public readonly offers: CrewSearchJobOfferListDto,
    public readonly allOffers: CrewSearchCommonJobOfferItemDto[],
    public readonly chipsContracts: SearchChipsItemDto[],
    public readonly chipsPositionGroups: SearchChipsItemDto[],
    public readonly chipsPositions: SearchChipsItemDto[],
    public readonly chipsExperiences: SearchChipsItemDto[],
    public readonly chipsGreeny: SearchChipsItemDto,
    public readonly countries: CountryDto[],
    public readonly cvVisaGroup: CvVisaGroupDto[],
    public readonly chipsCouple: SearchChipsItemDto,
    public readonly chipsCovid: SearchChipsItemDto,
    public readonly boatTypeSections: BoatTypeSectionDto[],
    public readonly boatTypes: BoatTypeDto[],
    public readonly chipsBoatTypeSections: SearchChipsItemDto[],
  ) {
  }
}

export class CrewSearchParamsPayload {
  readonly boatId: number;
  readonly positionGroup: number[] | null;
  readonly position: number[] | null;
  readonly lookingJobStatus: number | null;
  readonly availabilityStatus: number[] | null;
  readonly availabilityDate: string | null;
  readonly contractType: number[] | null;
  readonly expectedSalaryCurrency: number | null;
  readonly expectedSalaryMin: number | null;
  readonly expectedSalaryMax: number | null;
  readonly professionalSkills: number[] | null;
  readonly languageOneValue: number | null;
  readonly languageOneLevel: number | null;
  readonly languageTwoValue: number | null;
  readonly languageTwoLevel: number | null;
  readonly languageThreeValue: number | null;
  readonly languageThreeLevel: number | null;
  readonly yachtExperienceMin: number | null;
  readonly yachtExperienceMax: number | null;
  readonly greeny: ConditionalStatus | null;
  readonly notSmocking: ConditionalStatus | null;
  readonly notVisibleTattoos: ConditionalStatus | null;
  readonly covidVaccination: ConditionalStatus | null = null;
  readonly passportCountry: number | null = null;
  readonly visa: number[] | null = null;
  readonly couple: ConditionalStatus | null = null;
  readonly sort: CrewSearchSortType | null;
  readonly page: number | null;
  readonly limit: number | null;
  readonly myLocationLat: number | null = null;
  readonly myLocationLng: number | null = null;
  readonly distance: number | null = null;
  readonly regions: number[] | null = null;
  readonly search: string | null = null;
  readonly jobOfferId: number | null = null;
  readonly boatType: number[] | null = null;

  constructor(params: Params, maxDistance: number) {
    const {
      positionGroup,
      position,
      availabilityStatus,
      professionalSkills,
      myLocationLat,
      myLocationLng,
      distance,
      contractType,
      regions,
    } = params;
    this.boatId = params.boatId;
    this.positionGroup = parseParamsToArray(positionGroup, true);
    this.position = parseParamsToArray(position, true);
    this.lookingJobStatus = params.lookingJobStatus;
    this.availabilityStatus = parseParamsToArray(availabilityStatus, true);
    this.availabilityDate = params.availabilityDate;
    this.contractType = parseParamsToArray(contractType, true);
    this.expectedSalaryCurrency = params.expectedSalaryCurrency;
    this.expectedSalaryMin = params.expectedSalaryMin;
    this.expectedSalaryMax = params.expectedSalaryMax;
    this.professionalSkills = parseParamsToArray(professionalSkills, true);
    this.languageOneValue = params.languageOneValue;
    this.languageOneLevel = params.languageOneLevel;
    this.languageTwoValue = params.languageTwoValue;
    this.languageTwoLevel = params.languageTwoLevel;
    this.languageThreeValue = params.languageThreeValue;
    this.languageThreeLevel = params.languageThreeLevel;
    this.yachtExperienceMin = params.yachtExperienceMin;
    this.yachtExperienceMax = params.yachtExperienceMax;
    this.notSmocking = params.notSmocking;
    this.notVisibleTattoos = params.notVisibleTattoos;
    this.greeny = params.greeny;
    this.covidVaccination = params.covidVaccination;
    this.passportCountry = params.passportCountry;
    this.visa = parseParamsToArray(params.visa, true);
    this.couple = params.couple;
    this.sort = params.sort;
    this.page = params.page;
    this.limit = params.limit;
    if (myLocationLat && myLocationLng) {
      this.myLocationLat = params.myLocationLat;
      this.myLocationLng = params.myLocationLng;
      this.distance = distance || maxDistance;
    }
    this.regions = parseParamsToArray(regions, true);
    this.search = params.search;
    this.jobOfferId = params.jobOfferId;
    this.boatType = parseParamsToArray(params.boatType, true);
  }
}

export class CrewSearchMapParamsPayload extends CrewSearchParamsPayload {

  readonly topLeftLng: number;
  readonly topLeftLat: number;
  readonly bottomRightLat: number;
  readonly bottomRightLng: number;
  readonly zoom: number;

  constructor(params: Params, maxDistance: number) {
    super(params, maxDistance);
    this.topLeftLng = params.topLeftLng;
    this.topLeftLat = params.topLeftLat;
    this.bottomRightLat = params.bottomRightLat;
    this.bottomRightLng = params.bottomRightLng;
    this.zoom = params.zoom;
  }
}

export class CrewSearchUserNotePayload {
  constructor(
    public readonly boatId: number,
    public readonly userId: number,
    public readonly note: string,
  ) {
  }
}

export interface CrewSearchUserRatingI {
  jobOfferInvitationId: number;
  rating: number;
}

export class CrewSearchUserRatingPayload {
  constructor(
    public readonly boatId: number,
    public readonly jobOfferInvitationId: number,
    public readonly rating: number,
  ) {
  }
}

export class CrewSearchInputParams {
  readonly search: string | null = null;
  readonly searchLabel: string | null = null;

  constructor(
    search: SeazoneInputAutocompleteItemDto<string> | string | null = null,
  ) {
    if (!search) {
      return;
    }
    if (typeof search === 'string') {
      this.search = search;
      return;
    }
    const {value, label} = search;
    this.search = value;
    this.searchLabel = label;
  }
}
