import {CrewSearchStatus, CrewSearchStatusItem} from '@models/crew/crew.model';

const statusTranslation = (status: string): string => `search.crewSearch.status.${status}`;

export const CREW_SEARCH_STATUSES: CrewSearchStatusItem[] = [
  {
    id: CrewSearchStatus.LookingJob,
    name: statusTranslation('lookingJob'),
    style: 'looking-job',
    showInSearchFilter: true,
  },
  {
    id: CrewSearchStatus.Vacation,
    name: statusTranslation('vacation'),
    style: 'vacation',
    showInSearchFilter: true,
  },
  {
    id: CrewSearchStatus.OnBoard,
    name: statusTranslation('onBoard'),
    style: 'on-board',
    showInSearchFilter: false,
  },
  {
    id: CrewSearchStatus.NotActive,
    name: statusTranslation('notActive'),
    style: 'not-active',
    showInSearchFilter: false,
  },
  {
    id: CrewSearchStatus.Hidden,
    name: statusTranslation('hidden'),
    style: 'no-show',
    showInSearchFilter: false,
  },
];
