import {GeolocationDtoI} from '../location';
import {PaginationDto} from '../response';
import {SupplierContactPersonDto, SupplierRating, SupplierSearchStatus} from '../supplier';

export enum SupplierSearchSortType {
  Distance = 1,
  RatingFromMin,
  RatingFromMax,
  DateCreatedFromMin,
  DateCreatedFromMax
}

export class SuppliersSearchListDto {
  constructor(
    public readonly list: SuppliersSearchItemDto[],
    public readonly pagination: PaginationDto,
  ) {
  }
}

export class SuppliersSearchItemDto {
  constructor(
    public readonly supplier: SuppliersSearchUserDto,
    public readonly generalDetails: SuppliersSearchGeneralDto,
    public readonly contacts: SuppliersSearchContactsDto,
    public readonly contactPersons: SupplierSearchContactPersonDto[],
    public readonly catalog: SuppliersSearchCatalogDto,
    public readonly additional: SuppliersSearchAdditionalDto,
  ) {
  }
}

interface LabelBackground {
  readonly upper: string | null;
  readonly lower: string | null;
}

interface LabelDto {
  readonly text: string;
  readonly color: string | null;
  readonly background: LabelBackground;
}

export interface SearchCardLabelDto {
  readonly main: LabelDto;
  readonly additional: LabelDto | null;
}

interface SupplierLabels {
  readonly top: boolean;
  readonly special: SearchCardLabelDto | null;
}

export class SuppliersSearchUserDto {
  constructor(
    public readonly id: number,
    public readonly isBranch: boolean,
    public readonly branches: number,
    public readonly shareHash: string,
    public readonly labels: SupplierLabels,
  ) {

  }
}

export class SuppliersSearchGeneralDto {
  constructor(
    public readonly companyName: string,
    public readonly branchName: string | null,
    public readonly contactPerson: string | null,
    public readonly avatar: string,
    public readonly video: string,
    public readonly location: SuppliersSearchGeneralLocationDto,
    public readonly companyRating: SupplierRating,
    public readonly schedule: SuppliersSearchScheduleDto,
    public readonly tags: string[],
    public readonly statusSearch: SupplierSearchStatus,
    public readonly position?: string,
  ) {

  }
}

export class SuppliersSearchGeneralLocationDto implements GeolocationDtoI {
  constructor(
    public readonly address: string,
    public readonly distanceToBoat: number,
    public readonly lat: number,
    public readonly lng: number,
    public readonly street: string,
    public readonly house: string,
    public readonly office: string,
    public readonly zip: string,
  ) {
  }
}

export class SuppliersSearchScheduleDto {
  constructor(
    public readonly days: number[],
    public readonly workingHours: SuppliersSearchWorkingHours,
    public readonly breakHours: SuppliersSearchBreakHours,
    public readonly timeZone: string,
    public readonly open: boolean,
    public readonly quickReaction: boolean,
  ) {
  }
}

export class SuppliersSearchWorkingHours {
  constructor(
    public readonly from: string,
    public readonly to: string,
    public readonly roundClock: boolean,
  ) {
  }
}

export class SuppliersSearchBreakHours {
  constructor(
    public readonly from: string,
    public readonly to: string,
    public readonly notRelevant: boolean,
  ) {
  }
}

export class SuppliersSearchContactsDto {
  constructor(
    public readonly phones: SuppliersSearchContactsPhonesDto[],
    public readonly emails: string[],
    public readonly facebook: string | null,
    public readonly linkedin: string | null,
    public readonly instagram: string | null,
    public readonly skype: string | null,
    public readonly telegram: string | null,
    public readonly website: string | null,
  ) {
  }
}

export class SuppliersSearchContactsPhonesDto {
  constructor(
    public readonly code: string,
    // tslint:disable-next-line:variable-name
    public readonly number: string,
    public readonly viber: boolean,
    public readonly whatsApp: boolean,
  ) {
  }
}

export class SuppliersSearchCatalogDto {
  constructor(
    public readonly services: SuppliersSearchServicesDto[],
    public readonly brands: SuppliersSearchBrandsDto[],
  ) {
  }
}

export class SuppliersSearchServicesDto implements SupplierSearchMatchItem {
  constructor(
    public readonly matched: boolean,
    public readonly role: SuppliersSearchRoleDto,
    public readonly service: SuppliersSearchServiceDto,
  ) {
  }
}

export interface SupplierSearchMatchItem {
  readonly matched: boolean;
}

export class SupplierSearchGroupedServicesDto {
  constructor(
    public readonly role: SuppliersSearchRoleDto,
    public readonly services: SuppliersSearchServiceDto[],
  ) {
  }
}

export class SuppliersSearchRoleDto {
  constructor(
    public readonly name: string,
    public readonly icon: string,
    public readonly id: number,
  ) {
  }
}

export class SuppliersSearchServiceDto {
  constructor(
    public readonly name: string,
    public readonly roleId: number,
  ) {
  }
}

export class SuppliersSearchBrandsDto implements SupplierSearchMatchItem {
  constructor(
    public readonly name: string,
    public readonly logo: string,
    public readonly custom: boolean,
    public readonly certified: boolean,
    public readonly matched: boolean,
  ) {
  }
}

export class SupplierSearchCatalogLabeledList<T> {
  constructor(
    public readonly label: string,
    public readonly list: T[],
    public readonly allCount: number,
  ) {
  }
}

export class SuppliersSearchAdditionalDto {
  constructor(
    public readonly favorite: boolean,
    public note: string,
  ) {
  }
}

export interface ChangeSuppliersFavoriteStatusI {
  isFavorite: boolean;
  supplierId: number;
}

export type SupplierSearchContactPersonDto = Omit<SupplierContactPersonDto, 'form'>;
