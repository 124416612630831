import {Params} from '@angular/router';

import {SeazoneInputAutocompleteItemDto} from '@controls/seazone-autocomplete-input';
import {ExtremeRangeDto, RangeDto} from '@controls/seazone-range-slider';

import {
  BoatTypeDto,
  LanguageDto,
  SupplierBrandDto,
  SupplierCatalogRoleDto,
  SupplierCatalogServiceDto,
  SupplierGeneralTag,
  SupplierPaymentTermDto,
  SupplierRoleDto,
} from '../directories';
import {booleanToConditionalStatus, ConditionalStatus} from '../general';
import {SearchChipsItemDto} from '../search';

export class SupplierSearchFilterForm {
  constructor(
    public readonly roles: number[] | null = null,
    public readonly services: number[] | null = null,
    public readonly brands: number[] | null = null,
    public readonly rating: RangeDto | null = null,
    public readonly tags: number[] | null = null,
    public readonly languages: number[] | null = null,
    public readonly paymentTerms: number[] | null = null,
    public readonly boatTypes: number[] | null = null,
    public readonly boatLengthMin: number | null = null,
    public readonly boatLengthMax: number | null = null,
    public readonly boatTonnageMin: number | null = null,
    public readonly boatTonnageMax: number | null = null,
    public readonly openNow: boolean | null = null,
    public readonly quickReaction: boolean | null = null,
    public readonly search: SeazoneInputAutocompleteItemDto<string> | string | null = null,
  ) {
  }
}

export class SupplierSearchFilterParams {
  readonly roles: number[] | null = null;
  readonly services: number[] | null = null;
  readonly brands: number[] | null = null;
  readonly ratingMin: number | null = null;
  readonly ratingMax: number | null = null;
  readonly tags: number[] | null = null;
  readonly languages: number[] | null = null;
  readonly paymentTerms: number[] | null = null;
  readonly boatTypes: number[] | null = null;
  readonly boatLengthMin: number | null = null;
  readonly boatLengthMax: number | null = null;
  readonly boatTonnageMin: number | null = null;
  readonly boatTonnageMax: number | null = null;
  readonly openNow: ConditionalStatus | null = null;
  readonly quickReaction: ConditionalStatus | null = null;
  readonly search: string | null = null;
  readonly searchLabel: string | null = null;

  constructor(form: SupplierSearchFilterForm) {
    const {
      languages,
      brands,
      services,
      roles,
      rating,
      boatLengthMax,
      boatLengthMin,
      boatTonnageMax,
      boatTonnageMin,
      boatTypes,
      openNow,
      paymentTerms,
      quickReaction,
      tags,
      search,
    } = form;
    if (search) {
      if (typeof search === 'string') {
        this.search = search;
      } else {
        const {value, label} = search;
        this.search = value;
        this.searchLabel = label;
      }
    }
    this.roles = this.getArrayParam(roles);
    this.services = this.getArrayParam(services);
    this.brands = this.getArrayParam(brands);
    this.tags = this.getArrayParam(tags);
    if (rating) {
      this.ratingMin = rating.min;
      this.ratingMax = rating.max;
    }
    this.languages = this.getArrayParam(languages);
    this.paymentTerms = this.getArrayParam(paymentTerms);
    this.boatTypes = this.getArrayParam(boatTypes);
    this.boatLengthMin = boatLengthMin;
    this.boatLengthMax = boatLengthMax;
    this.boatTonnageMin = boatTonnageMin;
    this.boatTonnageMax = boatTonnageMax;
    this.quickReaction = quickReaction ? booleanToConditionalStatus(quickReaction) : null;
    this.openNow = openNow ? booleanToConditionalStatus(openNow) : null;
  }

  getArrayParam(val: any[] | null): any[] | null {
    if (!val || !val.length) {
      return null;
    }
    return val;
  }
}

export type SupplierSearchFilterQuery = {
  [key in keyof SupplierSearchFilterParams]?: string;
} & Params;

export class SupplierSearchFilterDirectories {
  constructor(
    public readonly roles: SupplierRoleDto[],
    public readonly services: SupplierCatalogServiceDto[],
    public readonly catalog: SupplierCatalogRoleDto[],
    public readonly brands: SupplierBrandDto[],
    public readonly ratingRange: ExtremeRangeDto,
    public readonly ratingChips: SearchChipsItemDto[],
    public readonly languages: LanguageDto[],
    public readonly tags: SupplierGeneralTag[],
    public readonly openNowChips: SearchChipsItemDto,
    public readonly quickReactionChips: SearchChipsItemDto,
    public readonly paymentTerms: SupplierPaymentTermDto[],
    public readonly boatTypes: BoatTypeDto[],
  ) {
  }
}
