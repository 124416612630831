import {Params} from '@angular/router';

export class CrewSearchMapMarkerWindowDto {
  constructor(
    public readonly id: number,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly avatar: string,
    public readonly age: number | null,
    public readonly lookingJobStatus: CrewSearchMapLookingJobDto,
    public readonly position: string | null,
    public readonly yachtExperience: number | null,
    public readonly greeny: boolean,
    public readonly expectedSalary: CrewSearchMapExpectedSalaryDto,
    public readonly location: CrewSearchMapLocationDto,
    public readonly distance: number | null,
  ) {
  }
}

export class CrewSearchMapLookingJobDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
  ) {
  }
}

export class CrewSearchMapExpectedSalaryDto {
  constructor(
    public readonly amount: number,
    public readonly period: string,
    public readonly currency: string,
  ) {
  }
}

export class CrewSearchMapLocationDto {
  constructor(
    public readonly lat: number,
    public readonly lng: number,
    public readonly originString: string,
  ) {
  }
}

export interface SearchMapCenterPositionLiteral extends google.maps.LatLngLiteral {
  zoom?: number | null;
}

export interface SearchMapMarkerLiteral extends SearchMapCenterPositionLiteral {
  id: number;
}

export class CrewSearchMapInfoWindowPayload {
  readonly crewIds: number[];
  readonly myLocationLat: number | null = null;
  readonly myLocationLng: number | null = null;
  readonly boatId: number;

  constructor(crewIds: number[], params: Params, boatId: number) {
    const {myLocationLat, myLocationLng} = params;
    if (myLocationLat && myLocationLng) {
      this.myLocationLat = myLocationLat;
      this.myLocationLng = myLocationLng;
    }
    this.crewIds = crewIds;
    this.boatId = boatId;
  }
}
