import {Params} from '@angular/router';

import {parseParamsToArray} from '@helpers/parse-params-to-array';

import {ConditionalStatus} from '../general';
import {Week} from '../general/days.model';

export type SuppliersSearchType = 'filter' | 'favorite' | 'notRelevant';

export class SuppliersSearchListParams {
  readonly boatId: number;
  readonly roles: number[] | null;
  readonly services: number[] | null;
  readonly brands: number[] | null;
  readonly ratingMin: number | null;
  readonly ratingMax: number | null;
  readonly tags: number[] | null;
  readonly languages: number[] | null;
  readonly boatTypes: number[] | null;
  readonly paymentTerms: number[] | null;
  readonly boatLengthMin: number | null;
  readonly boatLengthMax: number | null;
  readonly boatTonnageMin: number | null;
  readonly boatTonnageMax: number | null;
  readonly openNow: ConditionalStatus | null;
  readonly quickReaction: ConditionalStatus | null;
  readonly regions: number[] | null = null;
  readonly myLocationLat: number | null = null;
  readonly myLocationLng: number | null = null;
  readonly distance: number | null = null;
  readonly sort: any | null;
  readonly page: number | null;
  readonly limit: number | null;
  readonly search: number | null;

  constructor(
    params: Params,
    maxDistance: number,
  ) {
    const {
      myLocationLat,
      myLocationLng,
      distance,
      regions,
      roles,
      services,
      brands,
      tags,
      languages,
      boatTypes,
      paymentTerms,
    } = params;
    this.boatId = params.boatId;
    this.roles = parseParamsToArray(roles, true);
    this.services = parseParamsToArray(services, true);
    this.brands = parseParamsToArray(brands, true);
    this.ratingMin = Number(params.ratingMin) || null;
    this.ratingMax = Number(params.ratingMax) || null;
    this.tags = parseParamsToArray(tags, true);
    this.languages = parseParamsToArray(languages, true);
    this.boatTypes = parseParamsToArray(boatTypes, true);
    this.paymentTerms = parseParamsToArray(paymentTerms, true);
    this.boatLengthMin = Number(params.boatLengthMin) || null;
    this.boatLengthMax = Number(params.boatLengthMax) || null;
    this.boatTonnageMin = Number(params.boatTonnageMin) || null;
    this.boatTonnageMax = Number(params.boatTonnageMax) || null;
    this.openNow = Number(params.openNow) || null;
    this.quickReaction = Number(params.quickReaction) || null;
    if (myLocationLat && myLocationLng) {
      this.myLocationLat = params.myLocationLat;
      this.myLocationLng = params.myLocationLng;
      this.distance = distance || maxDistance;
    }
    this.regions = parseParamsToArray(regions, true);
    this.sort = params.sort;
    this.page = params.page;
    this.limit = params.limit;
    this.search = params.search;
  }
}

export class SuppliersSearchMapItemInfoDto {
  constructor(
    public readonly id: number,
    public readonly companyName: string,
    public readonly rating: number,
    public readonly schedule: SuppliersSearchInfoScheduleDto,
    public readonly shareHash: string,
  ) {
  }
}

export class SuppliersSearchInfoScheduleDto {
  constructor(
    public readonly days: Week | null,
    public readonly workingHours: SuppliersSearchInfoWorkingHours,
    public readonly breakHours: SuppliersSearchInfoBreakHours,
    public readonly timeZone: string,
    public readonly open: boolean,
    public readonly quickReaction: boolean,
  ) {
  }
}

export class SuppliersSearchInfoWorkingHours {
  constructor(
    public readonly from: string,
    public readonly to: string,
    public readonly roundClock: boolean,
  ) {
  }
}

export class SuppliersSearchInfoBreakHours {
  constructor(
    public readonly from: string,
    public readonly to: string,
    public readonly notRelevant: boolean,
  ) {
  }
}

export class SupplierSearchMapParamsPayload extends SuppliersSearchListParams {

  readonly topLeftLng: number;
  readonly topLeftLat: number;
  readonly bottomRightLat: number;
  readonly bottomRightLng: number;
  readonly zoom: number;

  constructor(
    params: Params,
    maxDistance: number,
  ) {
    super(params, maxDistance);
    this.topLeftLng = params.topLeftLng;
    this.topLeftLat = params.topLeftLat;
    this.bottomRightLat = params.bottomRightLat;
    this.bottomRightLng = params.bottomRightLng;
    this.zoom = params.zoom;
  }
}

export class SuppliersSearchMapInfoWindowPayload {
  readonly supplierIds: number[];
  readonly myLocationLat: number | null = null;
  readonly myLocationLng: number | null = null;
  readonly boatId: number | null;

  constructor(ids: number[], params: Params, boatId: number | null = null) {
    const {myLocationLat, myLocationLng} = params;
    if (myLocationLat && myLocationLng) {
      this.myLocationLat = myLocationLat;
      this.myLocationLng = myLocationLng;
    }
    this.supplierIds = ids;
    this.boatId = boatId || null;
  }
}

export class UpdateSuppliersUserStatusPayload {
  constructor(
    public readonly supplierId: number,
  ) {
  }
}

export class SuppliersSearchUserNotePayload {
  constructor(
    public readonly supplierId: number,
    public readonly note: string,
  ) {
  }
}

export interface SuppliersSearchCountMarked {
  readonly favorite: number;
  readonly notRelevant: number;
}
