import {Params} from '@angular/router';
import {footToMeters, milesToKilometers} from '@modules/unit-system';

import {CrewSearchSortType} from '@models/crew-search';
import {BoatTypeSectionType, SearchSortItemDto} from '@models/directories';
import {ConditionalStatus} from '@models/general';
import {JobSearchSortType} from '@models/job-search';
import {SearchChipsIconDto, SearchChipsItemDto} from '@models/search';
import {SupplierSearchSortType} from '@models/suppliers-search';
import {milesToKilometersCalculation} from '@unitSystem/helpers';
import {UnitSystem, UnitSystemTranslateKey} from '@unitSystem/models';

const getFilterName = (key: string): string => `search.filters.${key}`;

export const CREW_SEARCH_CONTRACT_CHIPS: SearchChipsIconDto[] = [
  new SearchChipsIconDto(2, 'run'),
  new SearchChipsIconDto(4, 'boat'),
  new SearchChipsIconDto(1, 'sun'),
  new SearchChipsIconDto(3, 'note'),
  new SearchChipsIconDto(5, 'toggle'),
];

export const CREW_SEARCH_POSITIONS_CHIPS: SearchChipsIconDto[] = [
  new SearchChipsIconDto(101, 'captain'),
];

export const CREW_SEARCH_POSITIONS_GROUPS_CHIPS: SearchChipsIconDto[] = [
  new SearchChipsIconDto(2, 'interior'),
  new SearchChipsIconDto(1, 'deck'),
  new SearchChipsIconDto(3, 'galley'),
  new SearchChipsIconDto(4, 'engineer'),
];

export const CREW_SEARCH_EXPERIENCE_CHIPS: SearchChipsItemDto[] = [
  new SearchChipsItemDto({min: 1, max: 10}, getFilterName('overOneYear')),
  new SearchChipsItemDto({min: 3, max: 10}, getFilterName('overThreeYear')),
];

export const SEARCH_BOAT_TYPE_SECTIONS_CHIPS: SearchChipsIconDto[] = [
  new SearchChipsIconDto(BoatTypeSectionType.Sail, 'sailBoat'),
  new SearchChipsIconDto(BoatTypeSectionType.Motor, 'boat'),
  new SearchChipsIconDto(BoatTypeSectionType.Catamaran, 'catamaran'),
];

export const CREW_SEARCH_GREENY_CHIPS: SearchChipsItemDto = new SearchChipsItemDto(
  true,
  getFilterName('greeny'),
  'greeny',
);

export const CREW_SEARCH_COUPLE_CHIPS: SearchChipsItemDto = new SearchChipsItemDto(
  true,
  getFilterName('couple'),
  'users',
);

export const CREW_SEARCH_COVID_CHIPS: SearchChipsItemDto = new SearchChipsItemDto(
  true,
  getFilterName('covidVac'),
  'covid',
);

export const getCrewSearchChipsList = (list: SearchChipsIconDto[], items: { id: number, name: string }[]): SearchChipsItemDto[] => list.reduce((acc: SearchChipsItemDto[], chip) => {
  const item = items.find(i => chip.id === i.id);
  if (item) {
    acc.push(new SearchChipsItemDto(chip.id, item.name, chip.icon));
  }
  return acc;
}, []);

export const generateDistanceList = (
  distance: number[],
  maxDistance: number,
  translateKey: UnitSystemTranslateKey,
  mapFn?: (value: number) => number,
): SearchChipsItemDto[] => {
  const array = distance.map(value => new SearchChipsItemDto(mapFn ? mapFn(value) : value, `unitSystem.${translateKey}.distanceShort`, null, value));
  array.push(new SearchChipsItemDto(mapFn ? mapFn(maxDistance) : maxDistance, getFilterName('max')));
  return array;
};

const NEAR_BOAT_KM = 500;
const MAX_DISTANCE_KM = 20_000;
const MAX_DISTANCE_MI = 12_000;

export const getBoatSearchDistanceChips = (system: UnitSystem): SearchChipsItemDto[] => {
  const distance = [100, NEAR_BOAT_KM, 1000];
  if (system === UnitSystem.IMPERIAL) {
    return generateDistanceList(distance, MAX_DISTANCE_MI, 'imperial', milesToKilometers);
  }
  return generateDistanceList(distance, MAX_DISTANCE_KM, 'metrical');
};

export const getNearBoatDistance = (system: UnitSystem): number => system === UnitSystem.METRIC ? NEAR_BOAT_KM : milesToKilometersCalculation(NEAR_BOAT_KM);

export const getMaxBoatDistance = (system: UnitSystem): number => system === UnitSystem.METRIC ? MAX_DISTANCE_KM : milesToKilometersCalculation(MAX_DISTANCE_MI);

export const getRangeStep = (system: UnitSystem, step: number): number => system === UnitSystem.METRIC ? step : milesToKilometers(step);

export const getRangeLengthStep = (system: UnitSystem, step: number): number => system === UnitSystem.METRIC ? step : footToMeters(step);

export const BOAT_SEARCH_NEAR_BOAT: SearchChipsItemDto = new SearchChipsItemDto(
  true,
  getFilterName('nearBoat'),
  'boat',
);

export const BOAT_SEARCH_NEAR_ME: SearchChipsItemDto = new SearchChipsItemDto(
  true,
  getFilterName('nearMe'),
  'location',
);

function getSortName(key: string): string {
  return `search.crewSearch.sort.${key}`;
}

function getSupplierSortName(key: string): string {
  return `search.supplierSearch.sort.${key}`;
}

export const CREW_SEARCH_SORT_LIST: SearchSortItemDto<CrewSearchSortType>[] = [
  {
    id: CrewSearchSortType.LastLogin,
    name: getSortName('lastLogin'),
  },
  {
    id: CrewSearchSortType.SalaryMinMax,
    name: getSortName('salaryMin'),
  },
  {
    id: CrewSearchSortType.SalaryMaxMin,
    name: getSortName('salaryMax'),
  },
  {
    id: CrewSearchSortType.ExperienceMinMax,
    name: getSortName('expMin'),
  },
  {
    id: CrewSearchSortType.ExperienceMaxMin,
    name: getSortName('expMax'),
  },
];

export const JOB_SEARCH_SORT_LIST: SearchSortItemDto<JobSearchSortType>[] = [
  {
    id: JobSearchSortType.LastUpdate,
    name: getSortName('lastUpdate'),
  },
  {
    id: JobSearchSortType.SalaryMinMax,
    name: getSortName('salaryMin'),
  },
  {
    id: JobSearchSortType.SalaryMaxMin,
    name: getSortName('salaryMax'),
  },
  {
    id: JobSearchSortType.BoatLengthMinMax,
    name: getSortName('boatLengthMin'),
  },
  {
    id: JobSearchSortType.BoatLengthMaxMin,
    name: getSortName('boatLengthMax'),
  },
];

export const SUPPLIERS_SEARCH_SORT_LIST: SearchSortItemDto<SupplierSearchSortType>[] = [
  {
    id: SupplierSearchSortType.Distance,
    name: getSupplierSortName('distance'),
  },
  {
    id: SupplierSearchSortType.RatingFromMin,
    name: getSupplierSortName('ratingFromMin'),
  },
  {
    id: SupplierSearchSortType.RatingFromMax,
    name: getSupplierSortName('ratingFromMax'),
  },
  {
    id: SupplierSearchSortType.DateCreatedFromMin,
    name: getSupplierSortName('dateCreatedFromMin'),
  },
  {
    id: SupplierSearchSortType.DateCreatedFromMax,
    name: getSupplierSortName('dateCreatedFromMax'),
  },
];

export const SEARCH_DAYWORK_CONTRACT_TYPE = 2;

export const SEARCH_DAYWORK_PARAMS: Params = {
  contractType: SEARCH_DAYWORK_CONTRACT_TYPE,
  nearBoat: ConditionalStatus.YES,
};
